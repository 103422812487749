// Color
$labelColor: #9ea0a5;
$logoColor: #1B7777;
$primaryTextColor: #fff;
$secondartTextColor: #000;
$menu-bg : #EEF3FB;
$primaryColor : #3b5a98;
$tabsColor : #3b5a98;
$navBarColor : #3b5a98;
$filterCheckboxColor : #3b5a98;

// Sidebar colors
$sidebarBorderColor : #3b5a98;
$sidebarBgColor : #3b5a98;

// Size
$labelSize: 12px;
$primaryBtnSize: 14px;

// Button Settings
$primaryBtnBorder: 4px;
$emptyBorder: 1px solid transparent;

// Font Settings
$fontMedium: 500;
$fontSizeForMiniCard: 24px;

//default constants
$white-bg: #ffffff;
$grey-bg: #f9f9fa;
$dark-grey-bg: #ebebeb;
$font-text: #000;
$grey-text: #6f7073;
$light-grey-text: #a5a7af;

$grey-border: #dfe2e5;
$dark-grey-border: #b8bbbe;
$button-grey: #f2f2f5;
$dark-shadow: #dfe2e5;

//information colors
$warning-text: #ff9801;
$warning-border: #ff9801;
$warning-bg: #faeddc;

$success-text: #50b154;
$success-border: #50b154;
$success-bg: #ebf5ea;

$error-text: #f44337;
$error-border: #f44337;
$error-bg: #feecec;

$info-text: #1c6ce1;
$info-border: #1c6ce1;
$info-bg: #e4edfb;

$primary-info-bg: #d4e3fa;
$primary-info-text: #1c6be1b6;
$primary-info-border: #1c6ce1;

$secondary-info-bg: #8080800f;
$secondary-info-text: #333;
$secondary-info-border: #333;

$tertiary-info-bg: #b33fb529;
$tertiary-info-text: #b33fb5;
$tertiary-info-border: #b33fb5;

$disabled-color : rgba(0, 0, 0, 0.25);

//button colors
$disabled-text-color   : #a5a7af;
$disabled-bg-color     : #f9f9fa;
$disabled-border-color : #dfe2e5;

$primary-btn-bg     : #3b5a98;
$primary-btn-border : #3b5a98;
$primary-text-color : #ffffff;
$primary-btn-shadow-color: rgba(59, 90, 152, 0.6);

$secondary-btn-bg     : #ffffff;
$secondary-btn-border : #d9d9d9;
$secondary-text-color : #444444;

$error-btn-bg     : #FDEBEB;
$error-btn-border : #FCCBCB;
$error-text-color : #E6492D;

$success-btn-bg     : #c3f6ce;
$success-btn-border : #50b154;
$success-text-color : #50b154;

$action-button-btn-bg     : #F3F7FC;
$action-button-text-color : #1665D8;
$action-button-btn-border : #CBDFFC;